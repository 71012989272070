<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit fault location details</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row>
                        <v-alert class="mt-3" type="info" dense>
                            If the fault address is different to the incident address, please fill this in below.
                        </v-alert>
                    </v-row>

                    <v-row>
                        <v-textarea
                            label="Fault location address / details"
                            v-model="incidentFaultLocationLocal"
                        ></v-textarea>
                    </v-row>

                    <v-row>
                        <v-switch
                            label="Did you attend site (construction site / streetworks site etc)?"
                            v-model="incidentDidYouAttendSiteLocal"
                            color="success"
                            inset
                        ></v-switch>
                    </v-row>

                    <v-row v-if="incidentDidYouAttendSiteLocal">
                        <v-textarea
                            label="Site details"
                            v-model="incidentSiteDetailsLocal"
                            placeholder="Please provide as much information as possible about the site you attended. For example; the name of the site manager or anyone you spoke to, the company responsible for the site, the names of any companies on the site, any permit references or site names and so on."
                            hint="Please provide as much information as possible about the site you attended. For example; the name of the site manager or anyone you spoke to, the company responsible for the site, the names of any companies on the site, any permit references or site names and so on."
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";

export default {
    props: {
        id: String,
        incidentFaultLocation: String,
        incidentDidYouAttendSite: Boolean,
        incidentSiteDetails: String,
    },
    
    data: function () {
        return {
            incidentFaultLocationLocal: this.incidentFaultLocation,
            incidentDidYouAttendSiteLocal: this.incidentDidYouAttendSite,
            incidentSiteDetailsLocal: this.incidentSiteDetails,

            isValid: true,
        };
    },
    
    methods: {

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.formBuilder.formData["vm-incident-fault-location"] = this.incidentFaultLocationLocal;
                result.aspectData.formBuilder.formData["vm-incident-did-you-attend-site"] = this.incidentDidYouAttendSiteLocal;
                result.aspectData.formBuilder.formData["vm-incident-site-details"] = this.incidentDidYouAttendSiteLocal ? this.incidentSiteDetailsLocal : null;
                
                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>
